import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Container, Row, Col } from "react-bootstrap"

import Seo from "../components/Seo"
import { Layout } from "../components/Layout"

const CompanyPage: React.FC = () => {
  const pageName: string = "会社概要";

  return (
    <Layout pageName={pageName}>
      <Seo title={`${pageName} | メープル・シーリング`} />
      <Container>
        <section>
          <Row>
            <Col xs={12}>
              <h2 className="subtitle">ごあいさつ</h2>
              <Row className="mt-3">
                <Col md={8} lg={10}>
                  <p>
                    当社のウェブサイトをご覧いただき、誠にありがとうございます。
                    <br />
                    富士宮市を中心に、シーリング工事・防水工事に携わっております、メープル・シーリングです。
                    <br />
                    25年以上の経験による豊富な知識と技術は、確実な施工や雨漏り解決に欠かせません。
                    <br />
                    メープル・シーリングでは、その経験を生かし、お客様とお客様の大切な財産である建物を第一に考え、最適な施工をご提案します。
                  </p>
                </Col>
                <Col md={4} lg={2} className="d-none d-md-block">
                  <StaticImage
                    src="../images/company-img.png"
                    alt="最適な施工をご提案します"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </section>
        <section>
          <Row>
            <Col xs={12}>
              <h2 className="subtitle">会社概要</h2>
              <div className="row my-4 mx-1">
                <table className="company-info">
                  <tbody>
                    <tr>
                      <th>会社名</th>
                      <td>メープル・シーリング</td>
                    </tr>
                    <tr>
                      <th>創業</th>
                      <td>1992年</td>
                    </tr>
                    <tr>
                      <th>代表者氏名</th>
                      <td>冨谷利幸</td>
                    </tr>
                    <tr>
                      <th>事業内容</th>
                      <td>シーリング・防水工事</td>
                    </tr>
                    <tr>
                      <th>所在地</th>
                      <td>418-0022&emsp;静岡県富士宮市小泉2385-3</td>
                    </tr>
                    <tr>
                      <th>電話・FAX</th>
                      <td>0544-24-5474</td>
                    </tr>
                    <tr>
                      <th>代表直通TEL</th>
                      <td>090-1295-8300</td>
                    </tr>
                    <tr>
                      <th rowSpan={4}>所有資格</th>
                      <td>１級防水施工技能士（シーリング防水）</td>
                    </tr>
                    <tr>
                      <td>職長・安全衛生責任者</td>
                    </tr>
                    <tr>
                      <td>有機溶剤作業主任者</td>
                    </tr>
                    <tr>
                      <td>高所作業車運転技能士</td>
                    </tr>
                    <tr>
                      <th>対応エリア</th>
                      <td>
                        富士・富士宮市を中心に静岡県東部&emsp;
                        <br className="d-sm-none" />
                        詳しくはお問い合わせください。
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Col>
          </Row>
        </section>
      </Container>
    </Layout>
  )
}

export default CompanyPage
